<template>
  <div>
    <div class="webview-test">
      <div class="device-info">
        {{ userAgent }}
      </div>
      <div class="ui-test-elements">
        <div class="test-el">
          <div class="el-title">desktop</div>
          <div class="el-state" :class="{ 'is-visible': !isMobile }"></div>
        </div>
        <div class="test-el">
          <div class="el-title">
            <span>mobile</span>
            <span v-if="isMobile">:{{ isIOS ? " iOS" : " Android" }}</span>
          </div>
          <div class="el-state" :class="{ 'is-visible': isMobile }"></div>
        </div>
        <div class="test-el">
          <div class="el-title">
            <span>Webview</span>
            <span v-if="fbWevView">: Facebook webview</span>
          </div>
          <div class="el-state" :class="{ 'is-visible': isWebView }"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    phone: "",
    code: "",
    fullPhoneNumber: "",
    check: false
  }),
  components: {
    // "mem-phone-field": () => import("@/components/base/BasePhoneFieldV2.vue"),
    // "mem-checkbox": () => import("@/components/base/BaseCheckbox.vue"),
  },
  computed: {
    userAgent() {
      if (!navigator.userAgent) return "";
      return navigator.userAgent.toLowerCase();
    },
    isMobile() {
      return /android|webos|iphone|ipad/i.test(this.userAgent);
    },
    isIOS() {
      return /iphone|ipod|ipad/.test(this.userAgent);
    },
    isWebView() {
      if (!this.isMobile) return false;

      let safari = /safari/.test(this.userAgent);

      let android = /android/.test(this.userAgent);
      let androidWV = /wv/.test(this.userAgent); // wv - is specific for android webview, might be an issues

      if (this.isIOS && !safari) return true;
      if (android && androidWV) return true;

      return false;
    },

    fbWevView() {
      return /FB/i.test(navigator.userAgent);
    },
  },
};
</script>
<style lang="scss" scoped>
.webview-test {
  display: flex;
  // display: none;
  flex-direction: column;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  .device-info {
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;

    padding: 16px 6px;
  }
  .ui-test-elements {
    .test-el {
      height: 40px;
      border: 1px solid #ffffff;
      margin-top: 8px;
      padding: 0 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .el-title {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .el-state {
        background-color: #ec1717;
        height: 34px;
        width: 34px;
        border-radius: 20px;
      }
      .is-visible {
        background-color: #59b752;
      }
    }
  }
}
</style>